// import { monaco } from "@monaco-editor/react";
import * as monaco from "monaco-editor";

export const provideCompletionItems = (
  model: monaco.editor.ITextModel,
  position: monaco.Position,
  context: monaco.languages.CompletionContext,
  token: monaco.CancellationToken,
): monaco.languages.ProviderResult<monaco.languages.CompletionList> => {
  var word = model.getWordUntilPosition(position);
  var range = {
    startLineNumber: position.lineNumber,
    endLineNumber: position.lineNumber,
    startColumn: word.startColumn,
    endColumn: word.endColumn,
  };
  return {
    suggestions: [
      {
        label: "mov",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base move mnemonic.",
        detail: "Move.",
        insertText: "mov",
        range: range,
      },
      {
        label: "add",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base add mnemonic.",
        detail: "Add.",
        insertText: "add",
        range: range,
      },
      {
        label: "sub",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base sub mnemonic.",
        detail: "Subtract.",
        insertText: "sub",
        range: range,
      },
      {
        label: "mul",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base mul mnemonic.",
        detail: "Multiply.",
        insertText: "mul",
        range: range,
      },
      {
        label: "lsf",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base lsf mnemonic.",
        detail: "Left shift register.",
        insertText: "lsf",
        range: range,
      },
      {
        label: "rsf",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base rsf mnemonic.",
        detail: "Right shift register.",
        insertText: "rsf",
        range: range,
      },
      {
        label: "and",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base and mnemonic.",
        detail: "And register.",
        insertText: "and",
        range: range,
      },
      {
        label: "or",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base or mnemonic.",
        detail: "Or register.",
        insertText: "or",
        range: range,
      },
      {
        label: "xor",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base xor mnemonic.",
        detail: "Xor register.",
        insertText: "xor",
        range: range,
      },
      {
        label: "inc",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base inc mnemonic.",
        detail: "Increment value in register.",
        insertText: "inc",
        range: range,
      },
      {
        label: "dec",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base dec mnemonic.",
        detail: "Decrement value in register.",
        insertText: "dec",
        range: range,
      },
      {
        label: "not",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base not mnemonic.",
        detail: "Not (invert) register.",
        insertText: "not",
        range: range,
      },
      {
        label: "jeq",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base jeq mnemonic.",
        detail: "Jump equal.",
        insertText: "jeq",
        range: range,
      },
      {
        label: "jne",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base jne mnemonic.",
        detail: "Jump not equal.",
        insertText: "jne",
        range: range,
      },
      {
        label: "jlt",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base jlt mnemonic.",
        detail: "Jump less than.",
        insertText: "jlt",
        range: range,
      },
      {
        label: "jgt",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base jgt mnemonic.",
        detail: "Jump greater than.",
        insertText: "jgt",
        range: range,
      },
      {
        label: "jle",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base jle mnemonic.",
        detail: "Jump less than or equal.",
        insertText: "jle",
        range: range,
      },
      {
        label: "jge",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base jge mnemonic.",
        detail: "Jump greater than or equal.",
        insertText: "jge",
        range: range,
      },
      {
        label: "psh",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base psh mnemonic.",
        detail: "Push value to stack.",
        insertText: "psh",
        range: range,
      },
      {
        label: "pop",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base pop mnemonic.",
        detail: "Pop value from stack.",
        insertText: "pop",
        range: range,
      },
      {
        label: "cal",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base cal mnemonic.",
        detail: "Call subroutine.",
        insertText: "cal",
        range: range,
      },
      {
        label: "ret",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base ret mnemonic.",
        detail: "Return from subroutine.",
        insertText: "ret",
        range: range,
      },
      {
        label: "hlt",
        kind: monaco.languages.CompletionItemKind.Keyword,
        documentation: "Base hlt mnemonic.",
        detail: "Halt all computation.",
        insertText: "hlt",
        range: range,
      },
    ],
  };
};

export const completionProvidor: monaco.languages.CompletionItemProvider = {
  provideCompletionItems,
};
